<template>
  <div class="v-col-sm-12 v-col-lg-12 v-col-12"
       v-for="item in dataResponse" :key="item.id">
    <div v-if="item.equipment && item.equipment.length > 0"
         class="v-theme--BLUE_THEME v-card--density-default elevation-10 rounded-md v-card--variant-elevated">
      <div class="v-card-text w-100 custom-card">
        <div id="chart"
             style="cursor: pointer"
             v-for="itemEquipment in item.equipment" :key="itemEquipment.id">
          <h1>{{ item.name }}</h1>
          <apexchart v-if="!isLoading"
                     :type="params.charts.type"
                     :height="params.charts.height"
                     :options="itemEquipment.options"
                     :series="itemEquipment.series">
          </apexchart>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EntityApi from "@/api/entityApi";

export default {
  name: "TemperatureControl",
  props: {
    params: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  components: {},
  data() {
    return {
      isLoading: true,
      series: [],
      categories:[],
      options: {},
      url: "",
      startDate: "",
      endDate: "",
      dataResponse: {},
    };
  },
  methods: {
    async getData() {
      let url = `${this.params.url}?`;
      this.params.paramsUri.forEach(param => {
        if (param.name && typeof param.name === "string") {
          url += `${param.name}=${param.name === "start_date" ? this.startDate : this.endDate}&`;
        }
      });

      this.dataResponse = await EntityApi.doRequest(url, this.params.type);
    },
    async getOptions() {
      let countRefrigerator;
      this.dataResponse.forEach(item => {
        countRefrigerator = 1;
        item.equipment.forEach(itemData => {
          itemData.data.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));
          itemData.options = this.setOptions(itemData.data.map(item => item.time), this.$t(`dashboard.chart.name-refrigerator`) + countRefrigerator)
          itemData.series = [
            {
              data: itemData.data.map(item =>  item.temperature),
              name: this.$t('dashboard.chart.temperature-refrigerator'),
            }
          ];
          countRefrigerator = countRefrigerator + 1
         });
      });
    },
    setOptions(categories, nameRefrigerator) {
      this.options  = {
        ...this.options , ...{
          xaxis: {
            categories: categories
          },
          yaxis: {
            title: {
              text:this.$t(`dashboard.chart.${this.params.charts.options.yaxis.title.text}`) + " °C"
            }
          },
          title:{
            text: this.$t(`dashboard.chart.${this.params.charts.options.title.text}`) + nameRefrigerator,
            align:"left"
          }
        }
      };
      return this.options;
    },
    async setDataSettingsDashboard() {
      const currentDate = new Date();

      const twoHoursAgo = new Date(currentDate);
      twoHoursAgo.setHours(currentDate.getHours() - this.params.charts.period);

      //this.startDate = "2023-08-27T07:00:00";
      //this.endDate =  "2023-08-27T08:59:59";

      this.startDate = this.formatDate(twoHoursAgo);
      this.endDate =  this.formatDate(currentDate);
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }
  },
  created() {

  },
  mounted() {
    this.setDataSettingsDashboard();
    this.options = this.params.charts.options;
    this.getData().then(() => {
      this.getOptions().then(() => {
        this.isLoading = false;
        this.$emit("hideSpinner");
      });
    });
  },
  watch: {
    _series() {
     // this.setOptions();
    }
  }
};
</script>
<style lang="scss">

.charts-price .vue-apexcharts .apexcharts-canvas {
  position: initial !important;
}

.charts-price .apexcharts-legend {
  display: none !important;
}

.apexcharts-svg {
  transform: none
}

</style>